import React from "react"
import styled from "styled-components";


const CalloutItemWrapper = styled.div`
  border-top: 2px solid #000000;
}
`;


const PostTitle = styled.div`
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  align-items: center;
  padding-top: 19px;
  padding-bottom: 10px;

  h3 {
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 1.75rem;
    line-height: 150%;
    color: ${props => props.theme.black};
    max-width: 90%;
    margin: 0;

    @media (max-width: ${props => props.theme.screenSmMax}) {
      font-size: 1.25rem;
    }

  }

}
`;


const CalloutItem = (props) => {


  return (
    <CalloutItemWrapper>
      <PostTitle>
        <h3>{props.title} </h3>
      </PostTitle>
      <p>{props.text}</p>
    </CalloutItemWrapper>
  )
}

export default CalloutItem;
