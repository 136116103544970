import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Hero from "../components/homepage/Hero";
import {graphql, useStaticQuery} from "gatsby";
import Highlights from "../components/homepage/Highlights";
import CaseStudies from "../components/homepage/CaseStudies";
import VideoSection from "../components/VideoSection";
import Posts from "../components/homepage/Posts";
import Save from "../icons/save.svg";
import Api from "../icons/api.svg";
import Responsive from "../icons/responsive.svg";
import {getImage} from "gatsby-plugin-image";
import BuyersGuideLink from "../components/BuyersGuideLink";
import Callouts from "../components/Callouts";


export default function Curriculum() {

  const data = useStaticQuery(graphql`{
  homehero: file(absolutePath: {regex: "/curriculum-hero.jpg/"}) {
    childImageSharp {
      gatsbyImageData(width: 650, layout: CONSTRAINED)
    }
  }
}
`);


  return (
    <Layout>

      <Hero
        title={'Curriculum Management Software'}
        text={'Create and manage your curriculum with customizable workflows and easy-to-manage permissions.'}
        linkText={'Get Started'}
        linkPath={'/start/'}
        buttonStyle={'button'}
        imgSrc={getImage(data.homehero)}
        imgAlt={'Faculty using curriculum management software on a laptop.'}
      />
      <Highlights
        title={'Streamline Your Curriculum Management Process'}
        text={'Manage all your curriculum changes and proposals in one easy-to-use system.'}
        highlightOneIcon={<Save/>}
        highlightOneTitle={'We’re Affordable'}
        highlightOneText={'We\'re typically the most affordable option for curriculum management software, and we do the initial buildout for you for no additional cost.'}
        highlightTwoIcon={<Api/>}
        highlightTwoTitle={'Easy Integration'}
        highlightTwoText={'We make it easy to integrate your curriculum, syllabi, and catalog content — and you can connect to your SIS or any other systems.'}
        highlightThreeIcon={<Responsive/>}
        highlightThreeTitle={'Customizable & Flexible'}
        highlightThreeText={'Fully customizable designs and layouts, and workflow capabilities that allow you to set granular permissions and approval processes before content goes live.'}

      />

      <Callouts
        title={'Curriculum management software made simple'}
        text={'No complex interfaces. \n' +
          'Only usability & accessibility.'}

        items={
          [
            {title: 'Audit trails', text: 'Keep track of who has edited content and when. Easily revert changes as needed.'},
            {title: 'Peace of mind', text: 'Make edits knowing our software ensures content is consistent and complete prior to publishing. '},
            {title: 'Permission to publish', text: 'Quickly assign different user permissions for editing and publishing.'},
            {title: 'Notifications & alerts', text: 'Users receive automatic email updates and can view dashboards of the latest activity.'},
            {title: 'Custom workflows', text: 'Add as many steps and users as you want. Your system, your choice.'},
            {title: 'API integrations', text: 'Connects to any existing CRM, SIS or related system, for extending functionality. '},
          ]
        }
      />
      <CaseStudies/>



      <VideoSection
        title={'Manage Your Curriculum with Advanced Workflows and Audit Trails'}
        text={'Workflows lets you distribute catalog and curriculum editing across campus, while still ensuring that everything goes through proper approval processes.'}
        vimeoid={'490721194'}
      />

      <BuyersGuideLink/>
      <Posts
        tag={'curriculum management software'}
      />


    </Layout>
  );

}

export function Head(props) {
  return (
    <Seo
      title="Curriculum Management Software"
      description='Curriculum management software built for colleges and universities — Clean Catalog.'
      canonical={props.location.pathname}
    />
  )
}


