import React from 'react';
import styled from "styled-components";
import Container from "../styles/Container";
import ContainerSmall from "../styles/ContainerSmall";
import CalloutItem from "./CalloutItem";


const CalloutsInterior = styled.div`
  background: #FFF;
  box-shadow: ${props => props.theme.boxShadow};
  // Overlay resources section
  position: relative;
  z-index: 10;
  padding-top: 30px;
  padding-right: 20px;
  padding-left: 20px;
  @media(min-width: ${props => props.theme.screenSm}) {
    padding-top: 40px;
    padding-left: 110px;
    padding-bottom: 60px;
    padding-right: 110px;
  }
`;


const StyledH2 = styled.h2`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 2.875rem;
  line-height: 54/46;
  text-align: center;
  letter-spacing: 0.03em;
  color: ${props => props.theme.black};
  margin-bottom: 15px;
  @media(max-width: ${props => props.theme.screenSmMax}) {
    font-size: 1.5rem;
  }
`

const StyledSubheading = styled.p`
  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 33/22;
  text-align: center;
  color: ${props => props.theme.black};
  margin-bottom: 72px;
  @media(max-width: ${props => props.theme.screenSmMax}) {
    font-size: 1rem;
  }
`



const StyledItemList = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: space-between;
  flex-wrap: wrap;
  > div {
    width: 30%;
    margin-bottom: 50px;
    }
  @media(max-width: ${props => props.theme.screenSmMax}) {
    flex-direction: column;
    > div {
      width: 100%;
      margin-bottom: 30px;
    }
  }
`

function Callouts(props) {


  return (


    <Container>

      <CalloutsInterior>
        <ContainerSmall>
          <StyledH2>{props.title}</StyledH2>
          <StyledSubheading>{props.text}</StyledSubheading>

            <StyledItemList>

              {props.items.map(item => {
                return <CalloutItem
                  title={item.title}
                  text={item.text}
                />
              })}
            </StyledItemList>


        </ContainerSmall>
      </CalloutsInterior>

    </Container>
  )
}

export default Callouts;
